import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGa from "react-ga";
import Home from "./pages/Home";
import CGU from "./pages/CGU";
import Privacy from "./pages/privacy";
import MobileAppRedirection from "./pages/MobileAppRedirection";
import Layout from "./layouts/components/Layout";
import { I18nPropvider, LOCALES } from "./i18n";
import CGUPremium from "./pages/CGUPremium";
import CU from "./pages/CU";
import MENTION from "./pages/MENTION";
import Mission from "./pages/Mission";
import AboutUs from "./pages/AboutUs";

function App() {
  const [language, setLanguage] = useState(LOCALES.ENGLISH);

  useEffect(() => {
    // Initialize Google Analytics
    ReactGa.initialize(["UA-142748287-2", "UA-142748287-1"]);
    ReactGa.pageview(window.location.pathname + window.location.search);

    // Load saved language from localStorage
    const savedLanguage = localStorage.getItem("language") || LOCALES.ENGLISH;
    setLanguage(savedLanguage);
  }, []);

  const languageList = [
    { code: "Français", value: LOCALES.FRANCAIS },
    { code: "English", value: LOCALES.ENGLISH },
    { code: "عرب", value: LOCALES.ARABE },
  ];

  const updateHotLanguage = (code) => {
    setLanguage(code);
    localStorage.setItem("language", code);
  };

  return (
    <I18nPropvider locale={language}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="cgu" element={<CGU />} />
            <Route path="cgu-premium" element={<CGUPremium />} />
            <Route path="cu" element={<CU />} />
            <Route path="mentions" element={<MENTION />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="mission" element={<Mission />} />
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="*" element={<MobileAppRedirection />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </I18nPropvider>
  );
}

export default App;
