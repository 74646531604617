import {LOCALES} from '../locales'

const EnglishLanguage = {
    [LOCALES.ENGLISH] : {
            
        /// Navbar ///
        'ser': "Services",
        'pre': "Premium",
        'eg': "Try for free",
        'tar': "Pricing",
        'disc' : "Discover Colorfol",
        'prem' : "Colofol Premium",
        'colart' : "Colorfol For Artists",
        'freedown' : "Free Download",
        'value' : 'Discover, Connect, Support your artist.',
        'covalue': "We are making it easy for you to buy, listen to, download and share your favourite music legally with your friends and family, all in one place.",
        'subvalue': "Thousands of songs by young talents, stars and big names in African music, podcasts, video clips and your events (concerts, showcases, etc.) all on one platform.",
        'fcta': 'Essayer Gratuitement',
        'pcta': 'Rejoignez plus de 3.000 utilisateurs et entreprises qui croyent en nous.',
        'hqa' : "High-quality audio for a truly immersive experience.",
        'supp' : "Support your artist with Colorfol",
        'colprem1' : "A powerful sound, without interruption with ",
        'colprem2' : "Colorfol Premium",

        'help' : "Help",
        'subpricing' : "Subscription and pricing",
        'downapp' : "Download the app",
        'getstart' : "Get started",

        /// Services section ///
        'disc_buy' : "Discover and buy songs and albums directly from your favorite artists.",
        'play_off' : "Play your favorite songs everywhere, even in offline mode",
        'playlist_fy': "Playlists designed for you, according to your listening",
        'pass_event' : "Get your Colorfol pass for an unforgettable event",
        'chall' : "Our challenge is to provide the best possible experience for fans, with an intuitive, simple interface and optimum audio quality, but also to support artists in their development and monetisation of their art.",
        'upcol' : "Upgrade to Colorfol Premium and take your music journey to the next level. Enjoy uninterrupted music playback, even in offline mode.",
        'getprem' : "Get Colorfol Premium",


        'offplay' : "Offline playback",
        'savelis' : "Save your data by listening offline",
        'adfre' : "Ad-free music listening",
        'enjomu' : "Enjoy uninterrupted music",
        'playevr' : "Play everywhere",
        'lispc' : "Listen on your speakers, PC and other favorite devices",
        'payyr' : "Pay your way",
        'paymob' : "Pay with Mobile Money, and more.",
        'knowmore': "Want to know more about us",
        'conta' : "Contact us for any questions or more information about us",
        'contactus' : "Contact Us",
        'cooming' :"Available soon",

        /// Services section ///
        'fs_mt': "Audio Streaming",
        'fs_ti': "Discover the best of African song",
        'fs_des': "Listen to the best of Cameroonian, Algerian, Ivorian and many other music and podcasts on your mobile. Discover all the new and favorite songs of the moment, the old hits, the news of your favorite artists (single, albums), stream the best hits, top African songs and discover young talents even more exceptional. Live an incomparable musical experience on colorfol. Stream and create your own playlists and share with your friends, download your favorite music and listen without internet connection.",
        'fs_cta': "Discover also our podcasts",

        'ss_mt': "Video Streaming",
        'ss_ti': "Discover the best of African video clips ",
        'ss_des': "Video is one of the most consumed contents on the internet, African artists are working hard to produce more and more exceptional videos, so we thought of making it available for you to enjoy. Discover, stream and download the best clips of the moment from your favorite artists and let yourself be transported by the rhythms, colors, dances, and visuals of your favorite songs.",
        'ss_cta': "Learn more",

        'ts_mt': "Events",
        'ts_ti': "Exclusive musical events at your fingertips",
        'ts_des': "Don't miss any information about showcases, cultural events and concerts of your favorite artists with the possibility to buy your tickets online. We inform you about the cultural news and events around you. Find out about current and upcoming cultural events taking place near you. Book your seats and buy tickets for concerts on Colorfol.",
        'ts_cta': "Learn more",

        /// Premium  section ///
        'pre_mt': "Enjoy your favorite songs with Colorfol premium",
        'pre_des': "Enjoy the best audio and video quality of your favorite titles, unlimited without any commercials or interruptions. Download your favorite songs and listen to them without an internet connection. Enjoy a built-in player to play all the audio content in your mobile.",

            /// Try free section ///
            'eg_mt': "Three easy steps",
            'eg_cta': "Get started",

            'fst_des': "Download the application from your Android or IPhone mobile.",
            'fst_ti': "Download ",

            'sst_des': "If you are a new user, it's very simple. Register and create your account.",
            'sst_ti': "Create an account",

            'tst_des': "Choose the country(ies), your music genres and enjoy the best of African music and podcasts.",
            'tst_ti': "Stream",

        /// Pricing section ///
        'pri_mt': "Pricing plan",
        'pri_des': "Discover our various offers.",

        'fpc_mt': "Premium",
        'fpc_ti': "0.2 €/Daily",
        'fpc_si': "0.8 €/Weekly",
        'fpc_foi': "02 €/Monthly",
        'fpc_fii': "20 €/Yearly",
        'fpc_sii': "Colorfol Njooh",

        'fpri': "Unlimited access to content",
        'spri': "Better sound quality",
        'tpri': "Choice of reading",
        'fopri': "Lyrics",
        'fipri': "Podcast",
        'sipri': "Online Radio",
        'sepri': "Buying tickets",
        'hepri': "Offline listening",
        'nipri': "No advertising",
        'tepri': "Unlimited access to video content",
        'elpri': "Unlimited access to audio content",
        'twpri': "Advertising",

        'spc_mt': "Pay",
        'tpc_mt': "Freemium",


            /// Footer ///
            'ft_fmt': "Services",
            'fmt_fi': "Music Streaming and Podcasts",
            'fmt_si': "Video Streaming",
            'fmt_ti': "Events",
            'fmt_foi': "Network Musical",
            'fmt_fii': "Reviews",
            'fmt_fiii' : "News",
            'ft_smt': "Contact Us",
            'smt_fi': "contact.cm@colorfol.com",
            'smt_si': "(+237) 690 81 72 84",
            'smt_ti': "Akwa - Douala - Cameroun",
            'smt_foi': "Contact.ci@colorfol.com",
            'smt_fii': "(+225) 0 787 458 582",
            'smt_sii': "Cocody Angré Petro Ivoire Abidjan- Côte d’Ivoire",
            'smt_sei': "contact.fr@colorfol.com",
            'smt_hui': "(+33) 6 05 87 57 04 ",
            'smt_nei': "Paris, France",


            'ft_tmt': "Get the app",
        'ourcompany' : "Our Company",
        'waw' : "Who We Are",
        'ov' : "Our Vision",
        'ot' : "Our Team",
        'fu' : "Follow Us",
        'diss' : "Discover",

        'lang': "English",
        'privacy': "Privacy Policy",
        'terms' : "Terms and Conditions",
        'copyright' : "Copyright ©2025 All right reserved Colorfol SARL",
    }
}

export default EnglishLanguage