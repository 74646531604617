import {LOCALES} from '../locales'

const FrenchLanguage = {
    [LOCALES.FRANCAIS] : {
        
        /// Navbar ///
        'ser': "Services",
        'pre': "Premium",
        'eg': "Essayer Gratuitement",
        'tar': "Tarifs",
        'disc' : "Découvrer Colorfol",
        'prem' : "Colofol Premium",
        'colart' : "Colorfol Artistes",
        'freedown' : "Telechargement Gratuit",

        /// Hero section ///
        'value' : 'Découvrez, connectez-vous, soutenez votre artiste.',
        'covalue': "Nous vous permettons d'acheter, d'écouter, de télécharger et de partager légalement votre musique préférée avec vos amis et votre famille, le tout en un seul endroit.",
        'subvalue': "Des milliers de chansons de jeunes talents, stars et grands noms de la musique africaine, podcast, clips vidéos et vos événements (concerts, showcase, etc…) le tout sur une même plateforme.",
        'fcta': 'Essayer Gratuitement',
        'pcta': 'Rejoignez plus de 3.000 utilisateurs et entreprises qui croyent en nous.',
        'hqa' : "Un son de haute qualité pour une expérience vraiment immersive.",
        'supp' : "Soutenez votre artiste avec Colorfol",
        'colprem1' : "Un son puissant, sans interruption avec ",
        'colprem2' : "Colorfol Premium",

        'help' : "Aide",
        'subpricing' : "Abonnement et tarification",
        'downapp' : "Télécharger l'application",
        'getstart' : "Commencer",

        /// Services section ///
        'disc_buy' : "Découvrez et achetez des chansons et des albums directement auprès de vos artistes préférés.",
        'play_off' : "Écoutez vos chansons préférées partout, même en mode hors ligne",
        'playlist_fy': "Des playlists conçues pour vous, en fonction de vos écoutes",
        'pass_event' : "Obtenez votre pass Colorfol pour un événement inoubliable",
        'chall' : "Notre défi est de fournir la meilleure expérience possible aux fans, avec une interface intuitive et simple et une qualité audio optimale, mais aussi de soutenir les artistes dans leur développement et la monétisation de leur art.",
        'upcol' : "Passez à Colorfol Premium et passez à l'étape suivante de votre voyage musical. Profitez d'une lecture ininterrompue de la musique, même en mode hors ligne.",
        'getprem' : "Obtenir Colorfol Premium",


        'offplay' : "Lecture hors ligne",
        'savelis' : "Économisez vos données en écoutant hors ligne",
        'adfre' : "Écoute de musique sans publicité",
        'enjomu' : "Profitez d'une musique ininterrompue",
        'playevr' : "Jouer de la musique partout",
        'lispc' : "Écoutez sur vos haut-parleurs, votre PC et vos autres appareils préférés appareils préférés",
        'payyr' : "Payer à sa façon",
        'paymob' : "Payer avec Mobile Money, et plus encore.",
        'knowmore': "Vous voulez en savoir plus sur nous ?",
        'conta' : "Contact pour plus de questions ou pour en savoir plus",
        'contactus' : "Contactez-Nous",
        'cooming' :"Bientôt disponible",


        'fs_mt': "Streaming Audio",
        'fs_ti': "Découvrez le meilleur de la chanson africaine",
        'fs_des': "Écoutez le meilleur de la musique camerounaise, algérienne, ivoirienne et bien d’autres et podcasts sur votre mobile. Découvrez toutes les nouveautés et coups de cœur du moment, les anciens succès, l’actualité de vos artistes préférés (single, albums) , streamez les meilleurs hits, top chansons africaines et découvrez des jeunes talents encore plus exceptionnels. Vivez une expérience musicale incomparable sur colorfol.   Streamez et créez vos propres playlists et partagez avec vos amis, téléchargez vos musiques favorites et écoutez sans connexion internet.",
        'fs_cta': "Découvrez aussi nos podcasts",

        'ss_mt': "Streaming Vidéo",
        'ss_ti': "Découvrez le meilleur des clips vidéos africains",
        'ss_des': "La vidéo est l’un des contenus les plus consommés sur internet , les artistes africains travaillent énormément pour nous produire des vidéos plus en plus exceptionnelles les uns que les autres, alors nous avons pensé à le rendre disponible pour vous afin que vous puissiez vous en délecter. Découvrez , streamez et téléchargez les meilleurs clips du moment de vos artistes favoris et laissez vous transporter par les rythmes, les couleurs, les danses, et les visuels de vos chansons préférées.",
        'ss_cta': "Découvrir",

        'ts_mt': "Événements",
        'ts_ti': "Des événements musicaux exclusifs à portée de main",
        'ts_des': "Ne manquez aucune information sur les bons plans de showcases, spectacles, événements culturels et concerts vos artistes préférés avec la possibilité d’acheter vos tickets en ligne. Nous vous informons de l’actualité culturelle et événementielle autour de vous.   Découvrez les évènements culturels actuels ou à venir qui se tiennent proche de vous. Réservez vos places et achetez des tickets pour des concerts sur Colorfol ",
        'ts_cta': "Découvrir",

        /// Premium  section ///
        'pre_mt': "Savourer vos morceaux favoris sans contraintes avec Colorfol premium.",
        'pre_des': "Profitez de la meilleure qualité audio et vidéo de vos titres préférés, en illimité sans aucune publicité et sans interruptions. Téléchargez vos chansons préférées et écoutez-les sans connexion internet . Profitez d’un lecteur intégré pour lire tout le contenu audio présent dans votre mobile.",

            /// Try free section ///
            'eg_mt': "Trois étapes faciles",
            'eg_cta': "Commencer",

            'fst_des': "Téléchargez l’application depuis votre mobile Android ou IPhone.",
            'fst_ti': "Téléchargement ",

            'sst_des': "Si vous êtes un nouvel utilisateur, c’est très simple Inscrivez-vous et créer votre compte.",
            'sst_ti': "Créer un compte",

            'tst_des': "Choisissez le(s) pays, vos genres musicaux et profitez du meilleur de la musique africaine et des podcasts.",
            'tst_ti': "Streamez",

        /// Pricing section ///
        'pri_mt': "Plan tarifaire",
        'pri_des': "Découvrez nos différentes offres.",

        'fpc_mt': "Premium",
        'fpc_ti': "0.2 €/Jour",
        'fpc_si': "0.8 €/Semaine",
        'fpc_foi': "02 €/Mois",
        'fpc_fii': "20 €/Année",
        'fpc_sii': "Colorfol Njooh",

        'fpri': "Accès illimité",
        'spri': "Zero publicité",
        'tpri': "Haute qualité",
        'fopri': "Écoute hors connexion",
        'fipri': "Lyrics",
        'sipri': "Radio en ligne",
        'sepri': "Achat de tickets",
        'hepri': "Playlists ",
        'nipri': "Podcasts",
        'tepri': "Accès illimité au contenu vidéo",
        'elpri': "Accès illimité aux contenus audios",
        'twpri': "Publicité",

        'spc_mt': "Payez",
        'tpc_mt': "Freemium",

        /// Footer ///
        'ft_fmt': "Services",
        'fmt_fi': "Streaming Musique et Podcasts",
        'fmt_si': "Streaming Video",
        'fmt_ti': "Evénements",
        'fmt_foi': "Musiques en réseau",
        'fmt_fii': "Commentaires",
        'fmt_fiii': "actualités",

        'diss' : "Découvrir",
        
        'ft_smt': "Contactez-Nous",
        'smt_fi': "contact.cm@colorfol.com",
       
        'smt_si': "(+237) 690 81 72 84",
        'smt_ti': "Akwa - Douala - Cameroun",
        'smt_foi': "Contact.ci@colorfol.com",
        'smt_fii': "(+225) 0 787 458 582",
        'smt_sii': "Cocody Angré Petro Ivoire Abidjan- Côte d’Ivoire",
        'smt_sei': "contact.fr@colorfol.com",
        'smt_hui': "(+33) 6 05 87 57 04 ",
        'smt_nei': "Paris, France",


        'ourcompany' : "Notre entreprise",
        'waw' : "Qui sommes-nous ?",
        'ov' : "Notre Vision",
        'ot' : "Notre équipe",
        'fu' : "Suivez-nous",

        'ft_tmt': "Obtenez l'app",

        'lang': "Anglais",
        'privacy': "Politique de confidentialité",
        'terms' : "Conditions générales d'utilisation",
        'copyright' : "Copyright ©2025 Tous droits réservés Colorfol SARL",

    }
};

export default FrenchLanguage